<template>
  <div class="exercise-list-page w-100">
    <div class="mt-6 mx-7">
      <div class="wrap__description__permission">
        <b-card>
          <b-row class="summary">
            <b-col class="total" cols="2">
              <div class="count">{{ summary.total }}</div>
              <p>Tổng voucher</p>
            </b-col>
            <b-col class="total active" cols="2">
              <div class="count">{{ summary.totalActive }}</div>
              <p>Đã sử dụng</p>
            </b-col>
            <b-col class="total inactive" cols="2">
              <div class="count">{{ summary.totalInActive }}</div>
              <p>Chưa sử dụng</p>
            </b-col>
            <b-col class="import" cols="6">
              <b-button
                class="btn btn-success ml-2"
                type="button"
                @click="importVoucher"
              >
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
                </span>
                Import Voucher
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
    <Wrapper>
      <template-table
        :column="column"
        :data="data"
        :paging="paging"
        :tableAction="false"
        :selectAction="false"
        :searchAction="false"
        @sortBy="sortRequest"
      >
        <template v-slot:body="{ item }">
          <td>
            <div class="d-flex align-items-center">
              <avatar size="40px" :src="item.logo" />
            </div>
          </td>
          <td>{{ item.title }}</td>
          <td>{{ item.code }}</td>
          <td>{{ formatDate(item.createDatetime) }}</td>
          <td>
            <div>
              <div class="status" :class="getStatus(item.status)">
                {{
                  getStatus(item.status) == 'inactive'
                    ? 'Chưa sử dụng'
                    : 'Đã sử dụng'
                }}
              </div>
            </div>
          </td>
        </template>
      </template-table>
    </Wrapper>
    <ModalImport @import-success="loadData(true)" />
  </div>
</template>
<script>
import { SET_MODAL } from '@/core/services/store/context.module';
export default {
  name: 'levelList',
  components: {
    ModalImport: () => import('./components/ModalImport.vue'),
  },
  data() {
    return {
      column: [
        {
          key: 'logo',
          label: 'Logo',
          style: {
            width: '15%',
          },
        },
        {
          key: 'title',
          label: 'Tiêu đề',
          style: {
            width: '40%',
          },
          class: 'sort-center',
        },
        {
          key: 'code',
          label: 'Mã voucher',
          style: {
            width: '15%',
          },
        },
        {
          key: 'createDatetime',
          label: 'Ngày tạo',
          style: {
            width: '10%',
          },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          style: {
            width: '20%',
          },
        },
      ],
      summary: {
        total: 0,
        totalActive: 0,
        totalInActive: 0,
      },
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      sort: {
        by: null,
        order: null,
      },
      data: [],
      showModal: false,
    };
  },
  computed: {
    searchParams() {
      return {
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
      };
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    sortRequest(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
      this.loadData();
    },
    loadData() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get('/Voucher', {
          params: this.searchParams,
        })
        .then((res) => {
          let data = res.items;
          this.data = data.map((item) => {
            return {
              ...item,
            };
          });
          this.paging.total = res.total;
          this.summary.total = res.total;
          this.summary.totalActive = res.totalActive;
          this.summary.totalInActive = res.totalInActive;
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
    formatDate(date) {
      const isValid = this.$moment(date, 'MM/DD/YYYY HH:mm:ss').isValid();
      if (!isValid) return null;
      return this.$moment(date).format('DD/MM/YYYY');
    },
    createLevel() {
      this.showModal = true;
      this.$store.commit(`context/${SET_MODAL}`, true);
    },
    resetModal() {
      this.showModal = false;
      this.levelId = null;
      this.$store.commit(`context/${SET_MODAL}`, false);
    },
    submitModal() {
      this.loadData();
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'inactive';
        case 1:
          return 'active';
        default:
          return 'draft';
      }
    },
    importVoucher() {
      this.$bvModal.show('modal-import');
    },
  },
};
</script>
<style lang="scss">
.sort-center {
  .sorting {
    justify-content: center;
  }
}
.status {
  padding: 6px 24px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  text-align: center;

  &.active {
    background: #ff5756;
  }

  &.inactive {
    background: #21a567;
  }

  &.draft {
    background: #888c9f;
  }
}
.summary {
  color: #0091a5;
  .total {
    padding: 0px 24px;
    border-right: 1px solid #f2f3f7;
    &.inactive {
      border: none;
    }
  }
  .count {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    margin: 0;
  }
  .active {
    color: #21a567;
  }
  .inactive {
    color: #ff5756;
  }
  .import {
    text-align: right;
  }
}
</style>
